import React from "react"
import styled from '@emotion/styled'
import {SEO} from '../components'

const Layout = styled.div`
  background: #f5f5f5;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    a {
      text-decoration: none;
      border-bottom: 2px solid black;
      color: black;
    }
`
const Box = styled.div`
    background: #e0f6ec;
    color: #0f7b72;
    padding: 20px;
    border-radius: 5px;
`

const ThankYouPage = () => (
  <Layout>
    <SEO 
        title={`Thank You | Dceed A.Ş.`}
        desc= "We received your submission and will get back to you shortly!"
    />
    <Box>
        <h1>Thank you</h1>
        <p>We received your submission and will get back to you shortly!</p>
        <p><a href="javascript:history.back()">← Back to our site</a></p>
    </Box>
  </Layout>
)

export default ThankYouPage